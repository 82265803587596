import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  inject,
  OnInit,
  signal,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-animated-digit',
  templateUrl: './animated-digit.component.html',
  styleUrls: ['./animated-digit.component.scss'],
})
export class AnimatedDigitComponent implements OnInit, AfterViewInit {
  @Input() duration!: number;
  @Input() digit!: number;
  @Input() steps!: number;
  @ViewChild('animatedDigit') animatedDigit!: ElementRef;

  isVisible: boolean = false;

  isVisibleSignal = signal(this.isVisible);
  private injector = inject(Injector);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['digit']) {
      this.animateCount();
    }
  }

  animateCount() {
    if (!this.duration) {
      this.duration = 2000;
    }

    if (typeof this.digit === 'number') {
      this.counterFunc(this.digit, this.duration, this.animatedDigit);
    }
  }
  reloadDigit() {
    console.warn('reloadDigit');
    this.animateCount();
  }

  counterFunc(endValue: any, durationMs: any, element: any) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element?.nativeElement?.textContent != null) {
        element.nativeElement.textContent = Math.abs(Math.floor(currentValue));
      }

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit() {
    if (this.digit) {
      this.animateCount();
    }

    toObservable(this.isVisibleSignal, { injector: this.injector })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          // console.warn('value', value);
          if (value) {
            this.animateCount();
          }
        },
      });
  }
}
