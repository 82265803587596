import { Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { Logger } from '@app/@shared';
import { AuthenticationService } from '@app/auth';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { BillingPriceDTO, ContactMeDTO, RegisterDTO } from 'src/generated/generatedEntities';
import { RegisterService } from './register.service';

const log = new Logger('Register-Form');

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent!.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return invalidCtrl || invalidParent;
  }
}

@UntilDestroy()
@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings', 'model_realEstate', 'billing'],
    },
  ],
})
export class RegisterFormComponent implements OnInit {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private formBuilder = inject(UntypedFormBuilder);
  private authenticationService = inject(AuthenticationService);
  private billingService = inject(BillingService);
  private registerService = inject(RegisterService);
  private baseLicenseService = inject(BaseLicenseService);
  registerForm!: UntypedFormGroup;
  selected = 'Schweiz';
  billingContactEqualsOrgContact = false;
  regBlock = false;
  isSiaIndividualMember: boolean = false;
  isSiaCorporateMember: boolean = false;
  isChecked: boolean = false;
  isLoading = false;
  error: string | undefined;
  hasStartFreeMonth = false;
  checkSuccess: boolean = false;
  errorUserExists: boolean = false;
  errorEmailExists: boolean = false;
  errorInvalidEmail: boolean = false;
  errorOrgNameExists: boolean = false;
  successMessgae: boolean = false;
  numEmp: any;
  doNotMatch: any;
  matcher = new MyErrorStateMatcher();
  checkPasswords: any;
  license: boolean = false;
  hasAcceptedConditions: boolean = false;
  hasAcceptedNoAnonymous: boolean = false;
  regiterBtn: boolean = true;
  contactSuccess: boolean = false;
  baseLicenses: any;
  discountInPercentIfSiaIndividual: any;
  discountInPercentIfSiaCorporate: any;
  price: any;

  // organisation!: any;
  baseLicense: any;
  numAdditionalUsers: any;
  selectedWithdrawalProductTypes: any;
  isAuthenticated!: boolean;
  registerAccount: any;
  organisation: any;
  errorMissing: any | null;
  registerComplete: boolean = false;

  constructor() {
    this.regForm();

    if (sessionStorage['register']) {
      this.registerAccount = sessionStorage['register'].registerAccount;
      this.organisation = sessionStorage['register'].organisation;
      this.numAdditionalUsers = sessionStorage['register'].numAdditionalUsers;
    }
  }

  ngOnInit(): void {
    this.regBlock = false;

    // const regData$ = this.billingService.fetchTentativePrice(registerObj);
    this.isAuthenticated = this.authenticationService.isAuthenticated();

    this.baseLicenseService
      .actives()
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: any) => {
          //console.log('reg data',res);
          this.baseLicenses = res;
          this.discountInPercentIfSiaIndividual = res[0].discountInPercentIfSiaIndividual;
          this.discountInPercentIfSiaCorporate = res[0].discountInPercentIfSiaCorporate;
        },
        error: (e) => {
          if (e.status === 200) {
            console.log('success');
            this.successMessgae = true;
            // this.checkSuccess = 'OK';
            this.hasStartFreeMonth = true;
          }
          if (e.status === 400 && e.error.message.includes('login already in use')) {
            this.errorUserExists = true;
          }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });
  }

  regForm() {
    this.registerForm = this.formBuilder.group({
      emailFormControl: [
        '',
        [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)],
      ],
      password: [, [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      firstname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      lastname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      orgName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      orgStreet: ['', Validators.required],
      orgZipcode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      orgPlace: ['', Validators.required],
      orgCountry: ['SWITZERLAND', Validators.required],
      orgPhone: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
      orgNumEmployees: ['', Validators.required, Validators.min(1), Validators.pattern('^[1-9]*$')],
      orgSiaIndividualMember: [''],
      orgSiaCorporateMember: [''],
      additionalAccounts: ['0'],
      billingContactEqualsOrgContact: [],
      billingEmail: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(100)]],
      billingOrgName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      billingFirstName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      billingLastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      billingDepartment: [''],
      billingStreet: ['', Validators.required],
      orgZipcodeBilling: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      orgPlaceBilling: ['', [Validators.required]],
      acceptConditions: [''],
      acceptedNoAnonymous: [''],
      chooseBuildingCosts: [''],
      chooseRebuildCosts: [''],
      chooseOperatingCosts: [''],
      chooseLCA: [''],
    });

    if (this.registerForm.invalid) {
      this.errorMissing = 'ERROR';
      // this.error = '';
    } else {
      this.errorMissing = null;
      this.showPrice();
    }
  }

  showRegBlock() {
    this.regBlock = true;
    this.regiterBtn = false;
  }

  onCheckboxToggle(value: any, event: boolean) {
    console.log('ckecked value', event);
    console.log('value', value);

    this.isSiaIndividualMember = false;
    this.isSiaCorporateMember = false;
    if (value.includes('isSiaIndividualMember')) {
      this.isSiaIndividualMember = event;
    } else if (value.includes('isSiaCorporateMember')) {
      this.isSiaCorporateMember = event;
    }
    //this.isSiaMemeber = '';
    // if (event && !this.isSiaIndividualMember) {
    //   this.isChecked = false;
    //   this.isSiaCorporateMember = false;
    //   this.isSiaIndividualMember = true;
    //   //this.isSiaMemeber = 'SIA Einzelmitglie'
    // } else if (event && this.isSiaIndividualMember) {
    //   this.isChecked = true;
    //   this.isSiaCorporateMember = true;
    //   this.isSiaIndividualMember = false;
    //   //this.isSiaMemeber = 'SIA Firmenmitglied'
    // }
  }

  // checkPasswords(group: FormGroup) {
  //   // here we have the 'passwords' group
  //   let pass = group.controls['password'].value;
  //   let confirmPass = group.controls['confirmPassword'].value;

  //   return pass === confirmPass ? null : { notSame: true };
  // }

  checkAcceptedCondition(e: any) {
    // console.log("event accepted value", e.checked);
    if (e.checked) {
      this.hasAcceptedConditions = false;
    }
  }

  checkNoAnonymous(e: any) {
    console.log('event hasAcceptedNoAnonymous value', e.checked);
    if (e.checked) {
      this.hasAcceptedNoAnonymous = false;
    }
  }

  isBillingAddreessSame: boolean = true;
  isAddressSameAsBillingAddress(e: any) {
    // console.log('ele', e.checked);
    e.checked = !e.checked;
    this.isBillingAddreessSame = e.checked;
  }

  checkRegistraion() {
    this.errorEmailExists = false;
    this.errorInvalidEmail = false;
    this.errorUserExists = false;
    this.errorOrgNameExists = false;

    let acceptedChecked = this.registerForm.controls['acceptConditions'].value;
    let isAnonymous = this.registerForm.controls['acceptedNoAnonymous'].value;

    // console.log("checked value", this.registerForm.controls['acceptConditions'].value);
    if (acceptedChecked) {
      this.hasAcceptedConditions = false;
    } else if (!this.hasAcceptedConditions) {
      this.hasAcceptedConditions = !this.hasAcceptedConditions;
      return;
    }

    if (isAnonymous) {
      this.hasAcceptedNoAnonymous = false;
    } else if (!this.hasAcceptedNoAnonymous) {
      this.hasAcceptedNoAnonymous = true;
      console.log(' hasAcceptedNoAnonymous', this.hasAcceptedNoAnonymous);
      return;
    }

    this.isLoading = true;
    console.log('form data', this.registerForm.value);

    const registerObj: RegisterDTO = {
      account: {
        activated: true,
        authorities: ['ROLE_USER'],
        createdBy: '',
        createdDate: new Date(),
        email: this.registerForm.controls['emailFormControl'].value,
        firstName: this.registerForm.controls['firstname'].value,
        id: -1,
        imageUrl: '',
        langKey: 'de',
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
        lastName: this.registerForm.controls['lastname'].value,
        login: '',
        organisationId: 0,
        organisationRole: 'ORG_ADMIN',
        password: this.registerForm.controls['password'].value,
        showPrivRefObject: true,
        showSysRefObject: true,
        withdrawalProductTypes: ['BUILDING_COSTS_AND_DATES'],
      },
      // baseLicenseId: 0,
      numAdditionalUsers: this.registerForm.controls['additionalAccounts'].value,
      organisation: {
        billingContact: {
          billingOrgName: this.registerForm.controls['billingOrgName'].value,
          comment: '',
          comment2: '',
          department: this.registerForm.controls['billingDepartment'].value,
          email: this.registerForm.controls['billingEmail'].value,
          firstName: this.registerForm.controls['billingFirstName'].value,
          lastName: this.registerForm.controls['billingLastName'].value,
          phone: '',
          place: this.registerForm.controls['orgPlaceBilling'].value,
          project: '',
          reference: '',
          street: this.registerForm.controls['billingStreet'].value,
          zipCode: this.registerForm.controls['orgZipcodeBilling'].value?.toString().trim(),
        },
        doAutoSendPaymentEmail: true,
        name: this.registerForm.controls['orgName'].value,
        numEmployees: this.registerForm.controls['orgNumEmployees'].value,
        phone: this.registerForm.controls['orgPhone'].value,
        place: this.registerForm.controls['orgPlace'].value,
        siaCorporateMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        siaIndividualMember: this.registerForm.controls['orgSiaIndividualMember'].value,
        street: this.registerForm.controls['orgStreet'].value,
        withdrawalProductTypes: ['BUILDING_COSTS_AND_DATES'],
        zipCode: this.registerForm.controls['orgZipcode'].value?.toString().trim(),
      },
      token: '', //this would be credit card token. currently not used.
    };

    this.checkPasswords = this.registerForm.controls['confirmPassword'].value;
    if (registerObj.account.password !== this.checkPasswords) {
      this.doNotMatch = true;
    } else {
      this.doNotMatch = false;
    }
    if (this.isChecked) {
      registerObj.organisation.billingContact = undefined;
    }

    if (registerObj.organisation.numEmployees < 51) {
      this.numEmp = registerObj.organisation.numEmployees;
    }
    if (registerObj.organisation.numEmployees > 50) {
      this.numEmp = registerObj.organisation.numEmployees;
    }
    // if (this.hasAcceptedConditions && this.hasAcceptedNoAnonymous) {

    const regData$ = this.authenticationService.checkRegDetails(registerObj);
    regData$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (data) => {
          console.log('reg data', data);
          if (data === 'OK') {
            this.successMessgae = true;
            this.checkSuccess = true;
            this.hasStartFreeMonth = true;
          }
          if (this.checkSuccess) {
            this.registerForm.disable();
          }
        },
        error: (e) => {
          // console.error('error status', e.status);
          // if (e.status === 200) {
          //   this.successMessgae = true;
          //   this.checkSuccess = true;
          //   this.hasStartFreeMonth = true;
          // }
          if (this.checkSuccess) {
            this.registerForm.enable();
          }
          if (e.status === 400 && e.error.includes('login already in use')) {
            this.errorUserExists = true;
          } else if (e.status === 400 && e.error.includes('email address already in use')) {
            this.errorEmailExists = true;
          } else if (e.status === 400 && e.error.includes('organisation name already in use')) {
            this.errorOrgNameExists = true;
          } else if (e.status === 400 && e.error.includes('invalid email')) {
            this.errorInvalidEmail = true;
          } else {
            this.error = 'ERROR';
          }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });

    this.showPrice();
    // }
  }

  @ViewChildren('bundleLCC,moduleBuilding,moduleRebuild,moduleOperating,moduleLCA') billings!: QueryList<MatCheckbox>;

  billingName: any = [];
  billingResponse!: BillingPriceDTO;
  billingCounter: number = 0;

  fetchBilling(e: MatCheckboxChange) {
    this.billingName!.length = 0;

    if (e.checked) {
      this.license = true;
    }
    let ar: string[] = [];
    // LCC_PACKAGE => Bundle all modules
    if (e.source.value == 'LCC_PACKAGE') {
      this.billings.forEach((x) => {
        if (
          x.value == 'BUILDING_COSTS_AND_DATES' ||
          x.value == 'OPERATING_COSTS' ||
          x.value == 'BUILDING_COSTS' ||
          x.value == 'REBUILD_COSTS' ||
          x.value == 'LCC'
        ) {
          x.checked = e.checked;
        }
      });
      if (this.billings.find((elem) => elem.value === 'LCC_PACKAGE' && elem.checked)) {
        ar = ['LCC_PACKAGE'];
      }
    } else {
      ar = this.billings.filter((elem) => elem.checked && elem.value != 'LCC').map((elem) => elem.value);
    }

    //prettier-ignore
    const lcc_package: MatCheckbox | undefined = this.billings.find((elem) => elem.value === 'LCC_PACKAGE' && elem.checked);
    //prettier-ignore
    const lca: MatCheckbox | undefined = this.billings.find((elem) => elem.value === 'LCA' && elem.checked);
    //prettier-ignore
    const nb: MatCheckbox | undefined = this.billings.find((elem) => elem.value === 'BUILDING_COSTS_AND_DATES' && elem.checked);
    //prettier-ignore
    const rebuild: MatCheckbox | undefined = this.billings.find((elem) => elem.value === 'REBUILD_COSTS' && elem.checked);
    //prettier-ignore
    const op: MatCheckbox | undefined = this.billings.find((elem) => elem.value === 'OPERATING_COSTS' && elem.checked);

    // if (lcc_package != null && nb != null && nb.checked) {
    //   ar.splice(ar.indexOf('BUILDING_COSTS_AND_DATES'), 1);
    // }
    // if (lcc_package != null && rebuild != null && rebuild.checked) {
    //   ar.splice(ar.indexOf('REBUILD_COSTS'), 1);
    // }
    // if (lcc_package != null && op != null && op.checked) {
    //   ar.splice(ar.indexOf('OPERATING_COSTS'), 1);
    // }
    debugger;

    if (lca != null && lca.checked) {
      ar.push('LCA');
    }

    this.billingCounter = ar.length;

    this.billingName = ar;
    this.errorEmailExists = false;
    this.errorInvalidEmail = false;
    this.errorUserExists = false;
    this.errorOrgNameExists = false;

    this.isLoading = true;

    const registerObj: RegisterDTO = {
      account: {
        activated: true,
        authorities: ['ROLE_USER'],
        createdBy: '',
        createdDate: new Date(),
        email: this.registerForm.controls['emailFormControl'].value,
        firstName: this.registerForm.controls['firstname'].value,
        id: -1,
        imageUrl: '',
        langKey: 'de',
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
        lastName: this.registerForm.controls['lastname'].value,
        login: this.registerForm.controls['emailFormControl'].value,
        organisationId: 0,
        organisationRole: 'ORG_ADMIN',
        password: this.registerForm.controls['password'].value,
        showPrivRefObject: true,
        showSysRefObject: true,
        withdrawalProductTypes: [], // this.billingName,
      },
      // baseLicenseId: 0,
      numAdditionalUsers: this.registerForm.controls['additionalAccounts'].value,
      organisation: {
        billingContact: {
          billingOrgName: this.registerForm.controls['billingOrgName'].value,
          comment: '',
          comment2: '',
          department: this.registerForm.controls['billingDepartment'].value,
          email: this.registerForm.controls['billingEmail'].value,
          firstName: this.registerForm.controls['billingFirstName'].value,
          lastName: this.registerForm.controls['billingLastName'].value,
          phone: '',
          place: this.registerForm.controls['orgPlaceBilling'].value,
          project: '',
          reference: '',
          street: this.registerForm.controls['billingStreet'].value,
          zipCode: this.registerForm.controls['orgZipcodeBilling'].value,
        },
        doAutoSendPaymentEmail: true,
        name: this.registerForm.controls['orgName'].value,
        numEmployees: this.registerForm.controls['orgNumEmployees'].value,
        phone: this.registerForm.controls['orgPhone'].value,
        place: this.registerForm.controls['orgPlace'].value,
        siaCorporateMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        siaIndividualMember: this.registerForm.controls['orgSiaIndividualMember'].value,
        street: this.registerForm.controls['orgStreet'].value,
        withdrawalProductTypes: this.billingName,
        zipCode: this.registerForm.controls['orgZipcode'].value,
      },
      token: '', //this would be credit card token. currently not used.
    };

    if (this.isChecked) {
      registerObj.organisation.billingContact = undefined;
    }

    if (registerObj.organisation.numEmployees < 51) {
      this.numEmp = registerObj.organisation.numEmployees;
    }

    if (e.checked) {
      registerObj.organisation.withdrawalProductTypes.push();
    }

    const regData$ = this.billingService.fetchTentativePrice(registerObj);
    regData$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: any) => {
          //console.log('reg data',res);
          this.billingResponse = res;
          this.price = res;
          console.log('billing response in dto format', this.billingResponse);
        },
        error: (e) => {
          if (e.status === 200) {
            console.log('success');
            this.successMessgae = true;
            // this.checkSuccess = 'OK';
            this.hasStartFreeMonth = true;
          }
          if (e.status === 400 && e.error.message.includes('login already in use')) {
            this.errorUserExists = true;
          } else if (e.status === 400 && e.error.message.includes('email address already in use')) {
            this.errorEmailExists = true;
          } else if (e.status === 400 && e.error.message.includes('organisation name already in use')) {
            this.errorOrgNameExists = true;
          } else if (e.status === 400 && e.error.message.includes('invalid email')) {
            this.errorInvalidEmail = true;
          } else {
            this.error = 'ERROR';
          }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });
  }

  //show price
  showPrice() {
    // fetch base license
    // const fetchLicenseDTO = {
    //   numEmployees: this.organisation.numEmployees
    // };
    const registerObj: RegisterDTO = {
      account: {
        activated: true,
        authorities: ['ROLE_USER'],
        createdBy: '',
        createdDate: new Date(),
        email: this.registerForm.controls['emailFormControl'].value,
        firstName: this.registerForm.controls['firstname'].value,
        id: -1,
        imageUrl: '',
        langKey: 'de',
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
        lastName: this.registerForm.controls['lastname'].value,
        login: this.registerForm.controls['emailFormControl'].value,
        organisationId: 0,
        organisationRole: 'ORG_ADMIN',
        password: this.registerForm.controls['password'].value,
        showPrivRefObject: true,
        showSysRefObject: true,
        withdrawalProductTypes: [], // this.billingName,
      },
      // baseLicenseId: 0,
      numAdditionalUsers: this.registerForm.controls['additionalAccounts'].value,
      organisation: {
        billingContact: {
          billingOrgName: this.registerForm.controls['billingOrgName'].value,
          comment: '',
          comment2: '',
          department: this.registerForm.controls['billingDepartment'].value,
          email: this.registerForm.controls['billingEmail'].value,
          firstName: this.registerForm.controls['billingFirstName'].value,
          lastName: this.registerForm.controls['billingLastName'].value,
          phone: '',
          place: this.registerForm.controls['orgPlaceBilling'].value,
          project: '',
          reference: '',
          street: this.registerForm.controls['billingStreet'].value,
          zipCode: this.registerForm.controls['orgZipcodeBilling'].value,
        },
        doAutoSendPaymentEmail: true,
        name: this.registerForm.controls['orgName'].value,
        numEmployees: this.registerForm.controls['orgNumEmployees'].value,
        phone: this.registerForm.controls['orgPhone'].value,
        place: this.registerForm.controls['orgPlace'].value,
        siaCorporateMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        siaIndividualMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        street: this.registerForm.controls['orgStreet'].value,
        withdrawalProductTypes: this.billingName,
        zipCode: this.registerForm.controls['orgZipcode'].value,
      },
      token: '', //this would be credit card token. currently not used.
    };
    this.baseLicenseService
      .fetchLicense(registerObj.organisation.numEmployees)
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: any) => {
          //console.log('reg data',res);
          this.baseLicense = res;
          this.price = res;
          console.log('price response in dto format', this.price);
        },
        error: (e) => {
          if (e.status === 200) {
            console.log('success');
          }
          // if (e.status === 400 && e.error.message.includes('login already in use')) {
          //   this.errorUserExists = true;
          // }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });

    registerObj.organisation.withdrawalProductTypes = this.selectedWithdrawalProductTypes;
    registerObj.numAdditionalUsers = this.numAdditionalUsers != null ? this.numAdditionalUsers : 0;
  }

  //create account
  register() {
    const registerObj: RegisterDTO = {
      account: {
        activated: true,
        authorities: ['ROLE_USER'],
        createdBy: '',
        createdDate: new Date(),
        email: this.registerForm.controls['emailFormControl'].value,
        firstName: this.registerForm.controls['firstname'].value,
        id: -1,
        imageUrl: '',
        langKey: 'de',
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
        lastName: this.registerForm.controls['lastname'].value,
        login: this.registerForm.controls['emailFormControl'].value,
        organisationId: 0,
        organisationRole: 'ORG_ADMIN',
        password: this.registerForm.controls['password'].value,
        showPrivRefObject: true,
        showSysRefObject: true,
        withdrawalProductTypes: [], // this.billingName,
      },
      // baseLicenseId: 0,
      numAdditionalUsers: this.registerForm.controls['additionalAccounts'].value,
      organisation: {
        billingContact: {
          billingOrgName: this.registerForm.controls['billingOrgName'].value,
          comment: '',
          comment2: '',
          department: this.registerForm.controls['billingDepartment'].value,
          email: this.registerForm.controls['billingEmail'].value,
          firstName: this.registerForm.controls['billingFirstName'].value,
          lastName: this.registerForm.controls['billingLastName'].value,
          phone: '',
          place: this.registerForm.controls['orgPlaceBilling'].value,
          project: '',
          reference: '',
          street: this.registerForm.controls['billingStreet'].value,
          zipCode: this.registerForm.controls['orgZipcodeBilling'].value,
        },
        doAutoSendPaymentEmail: true,
        name: this.registerForm.controls['orgName'].value,
        numEmployees: this.registerForm.controls['orgNumEmployees'].value,
        phone: this.registerForm.controls['orgPhone'].value,
        place: this.registerForm.controls['orgPlace'].value,
        siaCorporateMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        siaIndividualMember: this.registerForm.controls['orgSiaIndividualMember'].value,
        street: this.registerForm.controls['orgStreet'].value,
        withdrawalProductTypes: this.billingName,
        zipCode: this.registerForm.controls['orgZipcode'].value,
      },
      token: '', //this would be credit card token. currently not used.
    };

    const regData$ = this.authenticationService.createAccount(registerObj);
    regData$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: any) => {
          if (res === 'SUCCESS') {
            console.log('success');
            this.successMessgae = true;
            this.registerComplete = true;
            // this.checkSuccess = 'OK';
            this.hasStartFreeMonth = true;
          }
          console.log('register success data', res);
        },
        error: (e) => {
          if (e.status === 400 && e?.error?.message.includes('login already in use')) {
            this.errorUserExists = true;
          } else if (e.status === 400 && e?.error?.message.includes('email address already in use')) {
            this.errorEmailExists = true;
          } else if (e.status === 400 && e?.error?.message.includes('organisation name already in use')) {
            this.errorOrgNameExists = true;
          } else if (e.status === 400 && e?.error?.message.includes('invalid email')) {
            this.errorInvalidEmail = true;
          } else {
            this.error = 'ERROR';
          }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });
  }

  // contact Reg if employee > 51
  message: any;
  sendContact() {
    const registerObj: ContactMeDTO = {
      account: {
        activated: true,
        authorities: ['ROLE_USER'],
        createdBy: '',
        createdDate: new Date(),
        email: this.registerForm.controls['emailFormControl'].value,
        firstName: this.registerForm.controls['firstname'].value,
        id: -1,
        imageUrl: '',
        langKey: 'de',
        lastModifiedBy: '',
        lastModifiedDate: new Date(),
        lastName: this.registerForm.controls['lastname'].value,
        login: this.registerForm.controls['emailFormControl'].value,
        organisationId: 0,
        organisationRole: 'ORG_ADMIN',
        password: this.registerForm.controls['password'].value,
        showPrivRefObject: true,
        showSysRefObject: true,
        withdrawalProductTypes: this.billingName,
      },
      // baseLicenseId: 0,
      message: this.message,
      numAdditionalUsers: this.registerForm.controls['additionalAccounts'].value,
      organisation: {
        billingContact: {
          billingOrgName: this.registerForm.controls['billingOrgName'].value,
          comment: '',
          comment2: '',
          department: this.registerForm.controls['billingDepartment'].value,
          email: this.registerForm.controls['billingEmail'].value,
          firstName: this.registerForm.controls['billingFirstName'].value,
          lastName: this.registerForm.controls['billingLastName'].value,
          phone: '',
          place: this.registerForm.controls['orgPlaceBilling'].value,
          project: '',
          reference: '',
          street: this.registerForm.controls['billingStreet'].value,
          zipCode: this.registerForm.controls['orgZipcodeBilling'].value,
        },
        doAutoSendPaymentEmail: true,
        name: this.registerForm.controls['orgName'].value,
        numEmployees: this.registerForm.controls['orgNumEmployees'].value,
        phone: this.registerForm.controls['orgPhone'].value,
        place: this.registerForm.controls['orgPlace'].value,
        siaCorporateMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        siaIndividualMember: this.registerForm.controls['orgSiaCorporateMember'].value,
        street: this.registerForm.controls['orgStreet'].value,
        withdrawalProductTypes: this.billingName,
        zipCode: this.registerForm.controls['orgZipcode'].value,
      },
    };
    console.log('contact dto', registerObj);
    const regData$ = this.registerService.contactUs(registerObj);
    regData$
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (res: any) => {
          // if (res.status === 200) {
          //   console.log('success');
          // this.checkSuccess = 'OK';
          // }
          // console.log('register success data', res);
          // this.successMessgae = true;
          // this.hasStartFreeMonth = true;
          this.contactSuccess = true;
        },
        error: (e) => {
          this.contactSuccess = false;
          if (e.status === 400 && e.error.message.includes('login already in use')) {
            this.errorUserExists = true;
          } else if (e.status === 400 && e.error.message.includes('email address already in use')) {
            this.errorEmailExists = true;
          } else if (e.status === 400 && e.error.message.includes('organisation name already in use')) {
            this.errorOrgNameExists = true;
          } else if (e.status === 400 && e.error.message.includes('invalid email')) {
            this.errorInvalidEmail = true;
          } else {
            this.error = 'ERROR';
          }
          log.debug(`reg error: ${e}`);
          this.error = e;
        },
      });
  }
}
