import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';

@Directive({
  selector: '[kvisVisible]',
  standalone: true,
})
export class IsVisibleDirective implements AfterViewInit {
  private vcRef = inject(ViewContainerRef);
  @Input({ required: true }) elementVisible!: boolean;
  @Output() elementVisibleChange: EventEmitter<boolean> = new EventEmitter();

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement;

    const observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting);
    });
    observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    this.vcRef.clear();
    // console.warn('isIntersecting', isIntersecting);
    this.elementVisible = isIntersecting;
    this.elementVisibleChange.emit(isIntersecting);
  }
}
